import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { routing } from './app.routing';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UploadFilesComponent } from './pages/upload-files/upload-files.component';
import { FileUploadModule } from 'ng2-file-upload/file-upload/file-upload.module';
import { UsersComponent } from './pages/users/users.component';
import { EditComponent } from './pages/users/edit/edit.component';
import { AddComponent } from './pages/users/add/add.component';
import { ListComponent as UserListComponent } from './pages/users/list/list.component';
import { ChangePasswordComponent } from './pages/users/change-password/change-password.component';
import { UploadComponent } from './pages/users/upload/upload.component';

//servicios
import { ApiUsersService } from './services/api-users.service';
import { AuthService } from './services/auth.service';

//guards
import { AuthGuard } from './guards/auth.guard';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    PageNotFoundComponent,
    SidebarComponent,
    FooterComponent,
    NavbarComponent,
    UploadFilesComponent,
    UsersComponent,
    EditComponent,
    AddComponent,
    UserListComponent,
    ChangePasswordComponent,
    UploadComponent
  ],
  imports: [
    BrowserModule,
    routing,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase,"mya-web"),
    AngularFireDatabaseModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule // imports firebase/storage only needed for storage features
  ],
  providers: [ApiUsersService,AuthService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
