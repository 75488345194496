import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(private router: Router) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let userProfile : any = JSON.parse(localStorage.getItem('userProfile'));
        if (userProfile) {
            console.log(userProfile.rol);
            //verificar rol
            if(!userProfile.rol || userProfile.rol != "admin"){
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                return false;
            }
            
            // logged in so return true
            return true;
        }
 
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}