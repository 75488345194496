import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PasswordValidation } from './../../../validators/password-validation';

import { ApiUsersService } from '../../../services/api-users.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  public form: FormGroup;
  public response;

  constructor( private formBuilder: FormBuilder, private apiUsers: ApiUsersService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      usuario: ["", [Validators.required] ],
      nombre: ["", [Validators.required] ],
      apellido : ["",[Validators.required] ],
      rol: new FormControl("vendedor"),
      estado: new FormControl("activo"),
      contrasenia: ["",[Validators.required]],
      repetirContrasenia: ["", [Validators.required]],
      codVendedor: ["", [Validators.required]],
      nombreSucursal: ["", [Validators.required]],
      codSucursal: ["", [Validators.required]]
    },{
      validator: PasswordValidation.MatchPassword // validar repetir contraseña
    });

    this.suscribirseCampoRol();
  }

  onSubmit(formValue: any){
    console.log(formValue);
    this.response = null;
    this.apiUsers.crear(formValue).subscribe( (data : any) => {
      console.log(data);
      this.response = data;
      alert(""+this.response.message);
      this.form.reset();
    }, (error) => {
      console.log(error);
    });
  }

  suscribirseCampoRol(){
    this.form.get('rol').valueChanges.subscribe(
      (rol: string) => {
          if (rol === 'admin') {
            //eliminar validacion requerida para estos campos
            this.form.get('codVendedor').setValidators([Validators.nullValidator]);
            this.form.get('nombreSucursal').setValidators([Validators.nullValidator]);
            this.form.get('codSucursal').setValidators([Validators.nullValidator]);
            //limpiar campos cuando rol es admin
            this.form.get('codVendedor').setValue("");
            this.form.get('nombreSucursal').setValue("");
            this.form.get('codSucursal').setValue("");
          } else if (rol === 'vendedor') {
            //colocar validacion requerida cuando rol es vendedor
            this.form.get('codVendedor').setValidators([Validators.required]);
            this.form.get('nombreSucursal').setValidators([Validators.required]);
            this.form.get('codSucursal').setValidators([Validators.required]);
          }
          //actualizar nuevas reglas de validacion
          this.form.get('codVendedor').updateValueAndValidity();
          this.form.get('nombreSucursal').updateValueAndValidity();
          this.form.get('codSucursal').updateValueAndValidity();
      }
    );
  }
}
