// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // URL of development API
  apiUrl: "http://cot_api.myacomercial.com.ar", //produccion
  // apiUrl: 'http://localhost:3000', //testin local
  //apiUrl: "https://cot.myacomercial.com.ar:8080", // testing

  //dev
  /* firebase: {
    apiKey: "AIzaSyBp-duw9EtCry3u-z_HQ9WziU-LEWZyHHQ",
    authDomain: "myatest-c1391.firebaseapp.com",
    databaseURL: "https://myatest-c1391.firebaseio.com",
    projectId: "myatest-c1391",
    storageBucket: "myatest-c1391.appspot.com",
    messagingSenderId: "524212636900",
  }, */

  //prodV2
  firebase: {
    apiKey: "AIzaSyAo-wb7P0K-n-UUfatWS6yqYJ69VBSDPno",
    authDomain: "myaprodv2.firebaseapp.com",
    databaseURL: "https://myaprodv2.firebaseio.com",
    projectId: "myaprodv2",
    storageBucket: "myaprodv2.appspot.com",
    messagingSenderId: "112134408480",
  },
};
