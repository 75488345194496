import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PasswordValidation } from './../../../validators/password-validation';

import { ApiUsersService } from '../../../services/api-users.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  private subRouteParams: any;
  private key: any;
  private user: any;

  public form: FormGroup;
  public form2: FormGroup;

  public response;
  public grplist;
  public grplistuser;

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private apiUsers: ApiUsersService) { }

  ngOnInit() {
    this.initForm();
    this.subRouteParams = this.route.params.subscribe(params => {
      console.log(params);
      if(params.id){
        this.key = params.id;
        this.apiUsers.obtener(this.key).subscribe( (data: any) => {
          console.log(data);
          if(data.status == true){
            this.user = data.user;
            this.fillForm();

            //obtener grupos de listas de precios asignadas al usuario
            this.apiUsers.getGrpListasByUser(this.user.email).subscribe( (data: any) => {
              console.log(data);
              if(data.status == true){
                this.grplistuser = data.grplistauser;
                console.log(this.grplistuser);
              }
            }, (error) => {
              console.log(error);
            });

          }else{
            alert(data.message);
          }
        }, (error) => {
          console.log(error);
        });

        this.apiUsers.getGrpListas(this.key).subscribe( (data: any) => {
          console.log(data);
          if(data.status == true){
            this.grplist = data.grplista;
            console.log(this.grplist);
          }
        }, (error) => {
          console.log(error);
        });
        
      }
    });
  }

  ngOnDestroy() {
    this.subRouteParams.unsubscribe();
  }

  initForm(){
    this.form = this.formBuilder.group({
      usuario: ["", [Validators.required] ],
      nombre: ["", [Validators.required] ],
      apellido : ["",[Validators.required] ],
      rol: new FormControl("vendedor"),
      estado: new FormControl("activo"),
      codVendedor: ["", [Validators.required]],
      nombreSucursal: ["", [Validators.required]],
      codSucursal: ["", [Validators.required]],
      idDispositivo: [""]
    });

    this.form2 = this.formBuilder.group({
      grpListSelect: [null]
    })
    this.suscribirseCampoRol();
  }

  fillForm(){
    this.form.controls.usuario.setValue(this.user.usuario);
    this.form.controls.nombre.setValue(this.user.nombre);
    this.form.controls.apellido.setValue(this.user.apellido);
    this.form.controls.rol.setValue(this.user.rol);
    this.form.controls.estado.setValue(this.user.estado);
    this.form.controls.codVendedor.setValue(this.user.codVendedor);
    this.form.controls.nombreSucursal.setValue(this.user.nombreSucursal);
    this.form.controls.codSucursal.setValue(this.user.codSucursal);
    this.form.controls.idDispositivo.setValue(this.user.idDispositivo);
  }

  suscribirseCampoRol(){
    this.form.get('rol').valueChanges.subscribe(
      (rol: string) => {
          if (rol === 'admin') {
            //eliminar validacion requerida para estos campos
            this.form.get('codVendedor').setValidators([Validators.nullValidator]);
            this.form.get('nombreSucursal').setValidators([Validators.nullValidator]);
            this.form.get('codSucursal').setValidators([Validators.nullValidator]);
            //limpiar campos cuando rol es admin
            this.form.get('codVendedor').setValue("");
            this.form.get('nombreSucursal').setValue("");
            this.form.get('codSucursal').setValue("");
            this.form.get('idDispositivo').setValue("");
          } else if (rol === 'vendedor') {
            //colocar validacion requerida cuando rol es vendedor
            this.form.get('codVendedor').setValidators([Validators.required]);
            this.form.get('nombreSucursal').setValidators([Validators.required]);
            this.form.get('codSucursal').setValidators([Validators.required]);
          }
          //actualizar nuevas reglas de validacion
          this.form.get('codVendedor').updateValueAndValidity();
          this.form.get('nombreSucursal').updateValueAndValidity();
          this.form.get('codSucursal').updateValueAndValidity();
          this.form.get('idDispositivo').updateValueAndValidity();
      }
    );
  }

  onSubmit(formValue: any){
    console.log(formValue);
    this.response = null;
    formValue["uid"] = this.user.uid;
    formValue["key"] = this.user.key;
    this.apiUsers.actualizar(formValue).subscribe( (data : any) => {
      console.log(data);
      this.response = data;
      alert(""+this.response.message);
    }, (error) => {
      console.log(error);
    });
  }

  changed(e){
    // event comes as parameter, you'll have to find selectedData manually
    // by using e.target.data
    
    // this.grpListSelected = e.target.data;
    // this.grpListSelected = this.grpListSelectedValue;
    //console.log("this.grpListSelected: ",JSON.stringify(e.target.value));
    // console.log( this.form2.get('grpListSelect').value);
  }
  
  addGrplist(e) {
    //console.log("click", this.grpListSelected);
    console.log("this.user.uid: ", this.user.email);
    console.log(this.form2.get('grpListSelect').value);
    if (!this.form2.get('grpListSelect').value) {
      alert("Seleccione una opción valida");
      return;
    }

    this.apiUsers.grpListAdd({
      "codgrpl": this.form2.get('grpListSelect').value,
      "usuario": this.user.email
    }).subscribe( (data : any) => {
      console.log(data);
      this.response = data;
      if (this.response.status == true) { 
        //obtener grupos de listas de precios asignadas al usuario
        this.apiUsers.getGrpListasByUser(this.user.email).subscribe( (data2: any) => {
          console.log(data2);
          if(data2.status == true){
            this.grplistuser = data2.grplistauser;
            console.log(this.grplistuser);
          }
        }, (error) => {
          console.log(error);
        });
      }
      alert(""+this.response.message);
    }, (error) => {
      console.log(error);
    });
  }

  deleteGrplist(key) {
    console.log("key", key);
    if (!confirm("¿Está seguro de realizar esta acción?")) {
      return;
    }
    this.apiUsers.grpListDelete({
      "key": key,
    }).subscribe( (data : any) => {
      console.log(data);
      this.response = data;
      if (this.response.status == true) { 
        //obtener grupos de listas de precios asignadas al usuario
        this.apiUsers.getGrpListasByUser(this.user.email).subscribe( (data2: any) => {
          console.log(data2);
          if(data2.status == true){
            this.grplistuser = data2.grplistauser;
            console.log(this.grplistuser);
          }
        }, (error) => {
          console.log(error);
        });
      }
      alert(""+this.response.message);
    }, (error) => {
      console.log(error);
    });
  }
}
