import { Component, OnInit, Directive, ChangeDetectorRef } from '@angular/core';
import { FileDropDirective, FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
const API_URL = environment.apiUrl + "/api/";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})

export class UploadComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({ url: API_URL + "users/uploadFile", itemAlias: 'filetoupload[]' });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  detector;

  constructor(private ref: ChangeDetectorRef) {
    
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      console.log(fileItem);
      this.ref.detectChanges();
      this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
        console.log("ImageUpload:uploaded:", item, status, response);
        let resp = JSON.parse(response);
        if(resp && resp.status){
          alert(resp.message);
        }
    };
  }
   }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }



  ngOnInit() {
  }

}
