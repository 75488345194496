import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;
  public response: any;

  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, public afAuth: AngularFireAuth, private authService: AuthService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.form = this.formBuilder.group({
      usuario: ["", [Validators.required] ],
      contrasenia: ["",[Validators.required]]
    });
  }

  onSubmit(formValue: any){
    console.log(formValue);
    this.response = null;
    this.authService.login(formValue.usuario, formValue.contrasenia).then( data => {
      console.log(data);
      this.router.navigate(['./']);
    }, (errors) => {
      console.log(errors);
      this.response = {};
      this.response["errors"] = errors;
    });
  }
}
