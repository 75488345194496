import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUsersService } from '../../../services/api-users.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  public users : any = [];
  public usersFiltereds : any = [];
  public userProfile;
  public searchStr: any = "";

  constructor(private router: Router, private route: ActivatedRoute, private apiUsers: ApiUsersService) { }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
    this.apiUsers.todos().subscribe((data : any) => {
      console.log(data);
      if(data.status){
        this.users = data.users;
        this.usersFiltereds = this.users;
        console.log(this.users);
      }
    },
    (error) => {
      console.log(error);
    });
  }

  irAEditar(key: any){
    this.router.navigate(['../edit',key],{relativeTo:this.route});
  }

  eliminar(key: any){
    if(confirm("¿Está seguro de eliminar este usuario?")){
      this.apiUsers.eliminar(key).subscribe((data: any) => {
        console.log(data);
        if(data.status == true){
          //actualizar lista de usuario
          this.users = this.users.filter( (user) => {
            return user.key != key
          });
          this.usersFiltereds = this.usersFiltereds.filter( (user) => {
            return user.key != key
          });
        }
        alert(data.message);
      }, (error) => {
        console.log(error);
      });
    }
  }

  cambiarContrasenia(key: any){
    this.router.navigate(['../change_password',key], { relativeTo: this.route});
  }

  filtrarDatos(){
    console.log("searchStr: " + this.searchStr);
    
    let tempArray:Array<any> = [];
    this.users.forEach((item:any) => {
      let flag = false;

      if (item.usuario.toString().toLowerCase().match(this.searchStr.toLowerCase())) {
        flag = true;
      }
      if (item.nombre.toString().toLowerCase().match(this.searchStr.toLowerCase())) {
        flag = true;
      }
      if (item.apellido.toString().toLowerCase().match(this.searchStr.toLowerCase())) {
        flag = true;
      }
      if (item.rol.toString().toLowerCase().match(this.searchStr.toLowerCase())) {
        flag = true;
      }
      if (item.estado.toString().toLowerCase().match(this.searchStr.toLowerCase())) {
        flag = true;
      }
      if (item.nombreSucursal.toString().toLowerCase().match(this.searchStr.toLowerCase())) {
        flag = true;
      }

      if (flag) {
        tempArray.push(item);
      }
    });

    this.usersFiltereds = tempArray;
  }
}
