import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { PasswordValidation } from './../../../validators/password-validation';

import { ApiUsersService } from '../../../services/api-users.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  private subRouteParams;
  private key: any;
  public user: any;

  public form: FormGroup;
  public response;

  constructor(private router: Router, private route: ActivatedRoute,  private formBuilder: FormBuilder, private apiUsers: ApiUsersService) { }

  ngOnInit() {
    this.initForm();
    this.subRouteParams = this.route.params.subscribe( (params) => {
      if(params.id){
        this.key = params.id;
        this.apiUsers.obtener(this.key).subscribe( (data: any) => {
          console.log(data);
          if(data.status == true){
            this.user = data.user;
          }else{
            alert(data.message);
          }
        }, (error) => {
          console.log(error);
        });
      }
    });
  }

  ngOnDestroy() {
    this.subRouteParams.unsubscribe();
  }

  initForm(){
    this.form = this.formBuilder.group({
      contrasenia: ["",[Validators.required]],
      repetirContrasenia: ["", [Validators.required]]
    },{
      validator: PasswordValidation.MatchPassword // validar repetir contraseña
    });
  }

  onSubmit(formValue: any){
    console.log(formValue);
    this.response = null;
    formValue["uid"] = this.user.uid;
    this.apiUsers.actualizarContrasenia(formValue).subscribe( (data : any) => {
      console.log(data);
      this.response = data;
      alert(""+this.response.message);
      this.router.navigate(['../../list'], { relativeTo: this.route});
    }, (error) => {
      console.log(error);
    });
  }
}
