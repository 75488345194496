import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

const API_URL = environment.apiUrl + "/api/";

@Injectable()
export class ApiUsersService {

  constructor(private http: HttpClient) { }

  // API: GET /users
  public todos() {
    return this.http.get(API_URL + 'users');
  }

  // API: GET /users/obtener
  public obtener(key: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    return this.http.get(API_URL + 'users/obtener', { params });
  }

  // API: POST /crear
  public crear(user: any) {
    return this.http.post(API_URL + 'users/crear', user);
  }

  // API: POST /users/actualizar
  public actualizar(user: any) {
    return this.http.post(API_URL + 'users/actualizar', user);
  }

  // API: POST /users/actualizarIdDispositivo
  public actualizarIdDispositivo(key: any, idDispositivo: any) {
    return this.http.post(API_URL + 'users/actualizarIdDispositivo', {key: key, idDispositivo: idDispositivo});
  }

  // API: POST /users/actualizarEstado
  public actualizarEstado(uid: any, key: any, estado: any) {
    return this.http.post(API_URL + 'users/actualizarEstado', {uid: uid, key: key, estado: estado});
  }

   // API: POST /users/actualizarEstado
   public actualizarContrasenia(data) {
    return this.http.post(API_URL + 'users/actualizarContrasenia', data);
  }

  // POST /users/eliminar
  public eliminar(key: any) {
    var data = {
      key: key
    }
    return this.http.post(API_URL + 'users/eliminar', data);
  }

  // POST /users/uploadFile
  public uploadFile(file: any) {
    return this.http.post(API_URL + 'users/uploadFile', file);
  }

  // API: GET /users/grplista/getAll
  public getGrpListas(key: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    return this.http.get(API_URL + 'users/grplista/getAll', { params });
  }

   // API: POST /users/grplist/add
   public grpListAdd(data: any) {
    return this.http.post(API_URL + 'users/grplista/add', data);
  }

  // API: GET /users/grplista/onlyUser
  public getGrpListasByUser(usuario: any) {
    let params = new HttpParams();
    params = params.set('usuario', usuario);
    return this.http.get(API_URL + 'users/grplista/onlyUser', { params });
  }

  // API: POST /users/grplist/delete
  public grpListDelete(data: any) {
    return this.http.post(API_URL + 'users/grplista/delete', data);
  }
}
