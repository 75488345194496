import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable()
export class AuthService {

  constructor(public afAuth: AngularFireAuth, private db: AngularFireDatabase, private router: Router) { }

  login(usuario, contrasenia): Promise<any>{
    return new Promise( (resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(usuario + "@mya.com.ar", contrasenia).then(data => {
        console.log(data.user);
        let itemsRef = this.db.list('users');
        const userObserver = this.db.list('users', ref => ref.orderByChild('uid').equalTo(data.user.uid))
          .valueChanges().subscribe((users : any ) => {
            console.log(users);
            if(users.length > 0){
              if(users[0].rol === 'admin'){
                localStorage.setItem("userProfile", JSON.stringify(users[0]));
                resolve(true);
              }else{
                this.afAuth.auth.signOut();
                reject([{ code: "", message:"Acceso sólo para administradores"}]);
              } 
            }
            userObserver.unsubscribe();
         });
      }, (error) => {
        reject([{ code: error.code, message:error.message}]);
      });
    });
  }

  logout(){
    this.afAuth.auth.signOut().then((data) => {
      localStorage.removeItem('userProfile');
      this.router.navigate(['./login']);
    },(error) => {
      console.log(error);
    });
  }
}
