import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MainComponent } from './pages/main/main.component';
import { UploadFilesComponent } from './pages/upload-files/upload-files.component';
import { UsersComponent } from './pages/users/users.component';
import { AddComponent } from './pages/users/add/add.component';
import { EditComponent } from './pages/users/edit/edit.component';
import { ListComponent as UserListComponent } from './pages/users/list/list.component';
import { ChangePasswordComponent } from './pages/users/change-password/change-password.component';
import { UploadComponent } from './pages/users/upload/upload.component';

import { AuthGuard } from './guards/auth.guard';

const appRoutes: Routes = [
    { path: '', redirectTo: '/mainpage/users/list', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    {
        path: 'mainpage', component: MainComponent, children: [
            { path: 'uploadfiles', component: UploadFilesComponent, canActivate: [AuthGuard] },
            {
                path: 'users',
                component: UsersComponent,
                children: [
                  {path: '', redirectTo: 'list', pathMatch: 'full', canActivate: [AuthGuard]}, 
                  {path: 'list', component: UserListComponent, canActivate: [AuthGuard]}, 
                  {path: 'add', component: AddComponent, canActivate: [AuthGuard]},
                  {path: 'edit/:id', component: EditComponent, canActivate: [AuthGuard]}, 
                  {path: 'change_password/:id', component: ChangePasswordComponent, canActivate: [AuthGuard]},
                  {path: 'uploadUsers', component: UploadComponent, canActivate: [AuthGuard]}, 
                ],
                canActivate: [AuthGuard]
            }
        ],
        canActivate: [AuthGuard]
    },
    { path: '**', component: PageNotFoundComponent }
];

export const routing = RouterModule.forRoot(appRoutes);
