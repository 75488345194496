import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

//declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: 'uploadfiles', title: 'Administrar Archivos', icon: 'cloud_upload', class: '' },
    { path: 'users', title: 'Administrar Usuarios', icon: 'group', class: '' }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    public role: any;
    public user: any;

    constructor() {
        //this.user = JSON.parse(localStorage.getItem("currentUser"));
        this.role = 'ADMIN'; //this.user.roles[0].role;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    logout() {
        //localStorage.removeItem('currentUser');
    }
}
